import {
  Box,
  Divider,
  Heading,
  Text,
  HStack,
  Image,
  useBreakpointValue,
  VStack,
  Input,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Navbar from "../components/NavBar2";
import Intro from "../components/intro";
import Experience from "../components/Experience2";
import Footer from "../components/Footer";
import Organization from "../components/Projects";
import Poem from "./poem";

function NewYear() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const correctPassword = process.env.REACT_APP_PASSWORD; // Replace with the actual password

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      alert(correctPassword);
    }
  };

  if (!authenticated) {
    return (
      <Box
        maxW="100%"
        overflow="hidden"
        minH="100vh"
        bgPos="center"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="#e6d7ff"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text fontSize="lg" textAlign="center" mb="4">
          Please enter the password!
        </Text>
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          width="300px"
          mb="4"
        />
        <Button onClick={handlePasswordSubmit}>Submit</Button>
      </Box>
    );
  }

  if (isDesktop) {
    return (
      <Box
        maxW="100%"
        overflow="hidden"
        minH="100vh"
        bgPos="center"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="#e6d7ff"
      >
        <HStack w="100%" justify="right">
          <Image
            objectFit="cover"
            borderColor="white"
            src="Images/pomp.gif"
            alt="pompompurin drinking"
            padding="2px"
            w="5%"
          />
        </HStack>
        <Text w="100%" fontSize="lg" textAlign="center">
          happy new year!!
        </Text>
        <Text ml="5%" w="100%" fontSize="lg" textAlign="left"></Text>
        <Poem />
        <Divider
          borderColor="black"
          borderWidth="2px"
          justifyContent="center"
          alignContent="center"
          mt="2%"
          mb="2%"
          ml="20%"
          w="60%"
        />
        <Text w="100%" textAlign="center" p="1%">
          Designed by your mithai.
        </Text>
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box
        maxW="100%"
        overflow="hidden"
        minH="100vh"
        bgPos="center"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="#e6d7ff"
      >
        <VStack w="100%" justify="center">
          <Image
            objectFit="cover"
            borderColor="white"
            src="Images/pomp.gif"
            alt="pompompurin drinking"
            padding="2px"
            w="50%"
          />
        </VStack>
        <Text w="100%" fontSize="lg" textAlign="center">
          happy new year!!
        </Text>
        <Text ml="5%" w="100%" fontSize="lg" textAlign="left"></Text>
        <Poem />
        <Divider
          borderColor="black"
          borderWidth="2px"
          justifyContent="center"
          alignContent="center"
          mt="2%"
          mb="2%"
          ml="20%"
          w="60%"
        />
        <Text w="100%" textAlign="center" p="1%">
          Designed by your mithai.
        </Text>
      </Box>
    );
  }

  return null;
}

export default NewYear;
