import {
  Box,
  Divider,
  Text,
  HStack,
  Image,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Navbar from "../components/NavBar2";
import Intro from "../components/intro";
import Experience from "../components/Experience2";
import Footer from "../components/Footer";
import Organization from "../components/Projects";

function Poem() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentIndex, setCurrentIndex] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  if (isDesktop) {
    return (
      <HStack w="100%" h="100%" mt="5%" ref={ref}>
        <Image
          objectFit="cover"
          borderColor="white"
          src="Images/lily.png"
          alt="lily of the valley"
          ml="5%"
          mt="5%"
          w="15%"
        />
        <Box
          justifyContent="center"
          w="25%"
          p="4"
          h="100%"
          opacity={inView ? 1 : 0}
          transition="opacity 2s"
        >
          <Text w="100%" textAlign="center" mb="1">
            As I end the year,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I must reflect.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            On the person I love.
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            The girl I protect.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Do I begin with her beauty?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Enough to make angels cry.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Or start with her eyes?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Always full of surprise.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Do I go on about her smile?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Enough to make a poor man rich.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Or talk about her charms?
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Causing any frown to flip.
          </Text>
        </Box>
        <Box
          justifyContent="center"
          w="25%"
          p="4"
          h="100%"
          opacity={inView ? 1 : 0}
          transition="opacity 2s"
        >
          <Text w="100%" textAlign="center" mb="1">
            Even then,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            The world simply knows.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Words aren't enough,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            To describe my baby's glows.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Like a panda she rolls,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Like pesto she explores,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Bringing joy every day.
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Deserving all the bouquets.
          </Text>
        </Box>
        <Box
          justifyContent="center"
          w="25%"
          p="4"
          h="100%"
          opacity={inView ? 1 : 0}
          transition="opacity 2s"
        >
          <Text w="100%" textAlign="center" mb="1">
            Entering this next year
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I'm lucky to be her prince.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Serving her every need.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Calling her my princess.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Unforgiving time,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Will again rush us through the year.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            But spending time with my baby,
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Is all that I desire.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Happy new year my love,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            And may the next days treat you well.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I'll always be here with you,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Waiting for our bell.
          </Text>
        </Box>
        {/* <Image
            objectFit="cover"
            borderColor="white"
            src="Images/lily.png"
            alt="lily of the valley"
            mr="5%"
            w="15%"
          /> */}
      </HStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%" h="100%" mt="5%" ref={ref}>
        <Box
          justifyContent="center"
          w="90%"
          p="4"
          h="100%"
          opacity={inView ? 1 : 0}
          transition="opacity 2s"
        >
          <Text w="100%" textAlign="center" mb="1">
            As I end the year,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I must reflect.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            On the person I love.
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            The girl I protect.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Do I begin with her beauty?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Enough to make angels cry.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Or start with her eyes?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Always full of surprise.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Do I go on about her smile?
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Enough to make a poor man rich.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Or talk about her charms?
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Causing any frown to flip.
          </Text>

          <Text w="100%" textAlign="center" mb="1">
            Even then,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            The world simply knows.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Words aren't enough,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            To describe my baby's glows.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Like a panda she rolls,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Like pesto she explores,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Bringing joy every day.
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Deserving all the bouquets.
          </Text>

          <Text w="100%" textAlign="center" mb="1">
            Entering this next year
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I'm lucky to be her prince.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Serving her every need.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Calling her my princess.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Unforgiving time,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Will again rush us through the year.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            But spending time with my baby,
          </Text>
          <Text w="100%" textAlign="center" mb="8">
            Is all that I desire.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Happy new year my love,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            And may the next days treat you well.
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            I'll always be here with you,
          </Text>
          <Text w="100%" textAlign="center" mb="1">
            Waiting for our bell.
          </Text>
        </Box>
        <Image
          objectFit="cover"
          borderColor="white"
          src="Images/lily.png"
          alt="lily of the valley"
          // ml="5%"
          mt="5%"
          w="60%"
        />
        {/* <Image
            objectFit="cover"
            borderColor="white"
            src="Images/lily.png"
            alt="lily of the valley"
            mr="5%"
            w="15%"
          /> */}
      </VStack>
    );
  }

  return null;
}

export default Poem;
